import * as React from "react"
import { Helmet } from "react-helmet"

import { createGlobalStyle } from "styled-components"

import Header from "../components/header"
import Footer from "../components/footer"

import SUBJECTS_DATA from "../../data/subjects.json"

const Layout = (props) => {
  const { title, children, description, ...restProps } = props

  const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Open+Sans&display=swap');
    * {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      font-family: 'Open Sans';
      font-size: 16px;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: 'Inter';
    }
  `

  const subjectLinks = SUBJECTS_DATA.subjects.map((_) => ({
    label: _.label,
    slug: _.slug,
  }))

  const metaTitle = title ? `Summit Institute | ${title}` : "Summit Institute"

  const metaDescription = description
    ? `Summit Institute | ${description}`
    : "Summit Institute | Accredited category one NZQA private training provider."

  return (
    <main {...restProps} className="layout-wrapper">
      <Helmet
        title={metaTitle}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: `Summit Institute - ${metaTitle}`,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          // {
          //   property: `og:image`,
          //   content: `${site.siteMetadata.url}linkPreview.png`,
          // },
          // {
          //   property: `og:url`,
          //   content: site.siteMetadata.url,
          // },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: `Summit Institute - ${metaTitle}`,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]}
      ></Helmet>
      <GlobalStyle />
      <Header subjectLinks={subjectLinks} />
      <div className="header-placehold"></div>
      {children}
      <Footer subjectLinks={subjectLinks} />
    </main>
  )
}

export default Layout
