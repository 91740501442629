import React from "react"
import { Link } from "gatsby"
import { handleScrollTo } from "./navi"

import logo from "../images/logo.svg"

const Footer = (props) => {
  const subjectLinks = props.subjectLinks

  return (
    <div className=" footer-wrapper">
      <div className="layout-container">
        <div className="footer-container">
          <div>
            <img src={logo} alt="logo" style={{ width: '200px' }} />
            <p className="footer-content">
              Summit Institute is an accredited Category One NZQA private training
              provider offering flexible pathways to national qualifications.
            </p>
          </div>
          <div className="footer-link-group-wrapper">
            <div className="foot-link-group">
              <Link to="/subjects" className="foot-main-link">
                Subjects
              </Link>
              {subjectLinks.map((subject) => (
                <Link to={`/subject/${subject.slug}`} className="foot-sub-link">
                  {subject.label}
                </Link>
              ))}
            </div>
            
            <div className="foot-link-group">
              <Link to="/info" className="foot-main-link">
                Important info
              </Link>
              <Link
                to="/info"
                onClick={() => handleScrollTo("international_students")}
                className="foot-sub-link"
              >
                International students
              </Link>
              <Link
                to="/info"
                onClick={() => handleScrollTo("refund_policy")}
                className="foot-sub-link"
              >
                Refund policy
              </Link>
              <Link
                to="/info"
                onClick={() => handleScrollTo("pastoral_care")}
                className="foot-sub-link"
              >
                Pastoral care
              </Link>
              <Link
                to="/info"
                onClick={() => handleScrollTo("educational_performance")}
                className="foot-sub-link"
              >
                Educational performance
              </Link>
              <Link
                to="/info/homestay"
                onClick={() => handleScrollTo("homesty")}
                className="foot-sub-link"
              >
                Homesty
              </Link>
              <Link
                to="/study"
                onClick={() => handleScrollTo("how-you-can-study")}
                className="foot-sub-link"
              >
                How you can study
              </Link>
            </div>
            <div className="foot-link-group">
              <Link to="/blogs" className="foot-main-link">
                Blogs
              </Link>
            </div>
            <div className="foot-link-group">
              <Link to="/contact" className="foot-main-link">
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-footer">
          <div className="footer-footer__header">
            <div className="footer-footer__header__left">
              Copyright © Summit Institute 2021
            </div>
            <div className="footer-footer__header__right">
              <Link to="/privacy" className="foot-sub-link">
                Privacy policy
              </Link>
              {` | `}
              <Link to="/terms" className="foot-sub-link">
                Terms of use
              </Link>
            </div>
          </div>
          <div className="footer-footer__footer">
            <Link
              to="https://www.dacreed.com"
              className="foot-sub-link foot-sub-link--foot"
            >
              Powered by Dacreed
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
