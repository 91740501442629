import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"

import arrowUpIcon from "../images/green-arrow-up.svg"
import greenArrowDownIcom from "../images/green-arrow-down.svg"
import arrowDownIcon from "../images/black-arrow-down.svg"

export const handleScrollTo = (id) => {
  setTimeout(() => {
    const element = document.getElementById(id)
    const yOffset = -100
    const y =
      element?.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: "smooth" })
  }, 100)
}

const SubLink = (props) => {
  const { label, path, isMenuHidden, isScrollTo, ...restProps } = props

  if (isScrollTo) {
    return (
      <Link
        {...restProps}
        className={isMenuHidden ? "sub-link" : "sub-link-shown"}
        to={path.slice(0, 6)}
        onClick={() => handleScrollTo(path.slice(7))}
      >
        {label}
      </Link>
    )
  } else {
    return (
      <Link
        {...restProps}
        className={isMenuHidden ? "sub-link" : "sub-link-shown"}
        to={path}
      >
        {label}
      </Link>
    )
  }
}

const useOutSideHidden = (ref, setHidden) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setHidden()
      }
    }

    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  })
}

const Navi = (props) => {
  const [hidden, setHidden] = useState(true)

  const { label, path, subLinks, prefix, isMenuHidden, ...restProps } = props

  const wrapperRef = useRef(null)
  useOutSideHidden(wrapperRef, () => setHidden(true))

  return (
    <div
      {...restProps}
      className={isMenuHidden ? "header-link" : "header-link-shown"}
      ref={wrapperRef}
    >
      <div
        role="presentation"
        onMouseLeave={() => {
          if (window.innerWidth > 1024) setHidden(true)
        }}
      >
        <div className="navi-container">
          <Link
            to={path}
            className="navi-link"
            onMouseEnter={() => {
              if (window.innerWidth > 1023) {
                setHidden(false)
              }
            }}
          >
            {props.label}
          </Link>

          <button onClick={() => setHidden(!hidden)}>
            <img
              src={
                !hidden
                  ? arrowUpIcon
                  : !isMenuHidden
                  ? greenArrowDownIcom
                  : arrowDownIcon
              }
              className="navi-icon"
              alt="arrow"
            />
          </button>
        </div>
        {!hidden && (
          <div
            className={
              isMenuHidden ? "sub-link-wrapper" : "sub-link-wrapper-shown"
            }
          >
            {subLinks.map((subLink) => (
              <SubLink
                label={subLink.label}
                path={`/${prefix}/${subLink.slug}`}
                isMenuHidden={isMenuHidden}
                isScrollTo={subLink.slug[0] === "#"}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Navi
